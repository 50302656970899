
.grid_chart_growth_tooltip {
    display: grid;
    align-content: space-evenly;
    grid-template-columns: 117px 5px 55px 170px;
    grid-gap: 7x;
    padding: 5px;
    padding-left: 20px;
    font-size: 0.8rem;
    font-family: Roboto, sans-serif;
  }