.StylesParagraph{
    color: #454545;
    font-size: 90%;
}

.row-components{
    display:flex;
    flex-direction:row;
}

.recharts-cartesian-axis {    
    font-size: 0.8rem;
    font-family: Roboto, sans-serif;
}

.charts_title {    
    font-size: 0.9rem;
    font-family: Roboto, sans-serif;
}

.center_right_left_container{
    width:100%;
    text-align:center
}

.grid_container_map_tooltip {
    display: grid;
    align-content: space-evenly;
    grid-template-columns: 110px 75px 75px 125px;
    grid-gap: 7x;
    padding: 5px;
    padding-top: 10px;
    padding-right: 2px;
  }
  .grid_container_map_dates_tooltip {
    display: grid;
    align-content: space-evenly;
    grid-template-columns: 120px 110px;
    grid-gap: 7x;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-top: 0px;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .grid_container_tooltip {
    background:white;
    padding: 10px;
    border-width: 1px;
    border-color: lightgray;
    border-style: solid;
    font-size: 0.8rem;
    font-family: Roboto, sans-serif;
}
  .grid_allstates_chart_tooltip {
    display: grid;
    align-content: space-evenly;
    grid-template-columns: 22px 68px 22px 68px 22px 68px;
    grid-gap: 7x;
    padding: 5px;
    padding-left: 30px;
    font-size: 0.8rem;
    font-family: Roboto, sans-serif;
}

.titleHeader {
  width: 1000px;
  display: block;
  display: grid;
  align-content: space-evenly;
  grid-template-columns: 220px auto 220px;
  grid-gap: '10px';
  
};


.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  vertical-align: middle;
}

.grid_bar_chart_tooltip {
  display: grid;
  align-content: space-evenly;
  grid-template-columns: 90px 5px 60px 75px;
  grid-gap: 7x;
  padding: 5px;
  padding-left: 30px;
  font-size: 0.8rem;
  font-family: Roboto, sans-serif;
}

.grid_bar_chart_tooltip_rate {
  display: grid;
  align-content: space-evenly;
  grid-template-columns: 140px 5px 60px;
  grid-gap: 7x;
  padding: 5px;
  font-size: 0.8rem;
  font-family: Roboto, sans-serif;
}

.div_align_vmiddle > * {
  vertical-align: middle;
}

.text_align_vmiddle {
  display: inline-block;
  margin: 0;
  font-size: .8rem;
  font-family: Roboto, sans-serif;
}

.grid-parent {
  display: grid;
  grid-template-columns: 1fr 1fr
}